import React, { useContext, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import {
  Container,
  Box,
  SimpleGrid,
  FormControl,
  Input,
  Textarea,
  Select,
  CheckboxGroup,
  Checkbox,
  Center,
} from '@chakra-ui/react'
import { H1, H4 } from '../theme/Headings'
import { spacing, colors, breakpoints } from '../theme/themeAlopex'
// import BackgroundImage from 'gatsby-background-image'
import ColorContext from '../context/ColorContext'
// Newsletter
import Newsletter from '../components/Newsletter'
// Mapbox
import mapboxgl from '!mapbox-gl'
import ReactDOM from 'react-dom'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = "pk.eyJ1IjoiYWxvcGV4aWQiLCJhIjoiNGRhOGQ0YWJjYWZiNjMwM2YyYmE1OTA1Nzc5ZWI5ODEifQ.qyNsa7LBqCtnA_wredr2UQ"

const Popup = ({ placeName, placeAddress, phone }) => (
  <Box borderRadius="10px">
    <H4>{placeName}</H4>
    <Box mb="8px" dangerouslySetInnerHTML={{__html: placeAddress}}/>
    <div>
      {phone && (
        <>
          <a href={`tel:${phone}`}>{phone}</a>
          <br />
        </>
      )}
    </div>
  </Box>
)

const ContactPage = ({ data }) => {
  const { color } = useContext(ColorContext)
  
  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  const mapContainer = useRef()
  const popUpRef = useRef(new mapboxgl.Popup({ offset: 15 }))

  // this is where all of our map logic is going to live
  // adding the empty dependency array ensures that the map
  // is only rendered once
  useEffect(() => {
    // create the map and configure it
    // check out the API reference for more options
    // https://docs.mapbox.com/mapbox-gl-js/api/map/
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/alopexid/ckydfl77p8vy814o95fr01mmn",
      center: [-149.108277, 61.600465],
      zoom: 17,
      bearing: 32.20,
      pitch: 59.00,
      scrollZoom: false
    })

    // only want to work with the map after it has fully loaded
    // if you try to add sources and layers before the map has loaded
    // things will not work properly
    map.on("load", () => {
      // load geojson data
      map.addSource("alopex-locations", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "properties": {
                "place_name": "Alopex Interaction Design",
                "place_address": "564 S Denali St<br />Palmer, Alaska 99645",
                "phone": "907-952-5953",
                "icon": "Alopex-badge-black",
                "icon_size": 1,
                "text_offset": 3,
                "text_size": 18
              },
              "geometry": {
                "coordinates": [
                  -149.108305,
                  61.60067
                ],
                "type": "Point"
              },
              "id": "bf29660c5b7b454950ae6c1b249d6ab6"
            },
            {
              "type": "Feature",
              "properties": {
                "place_name": "Palmer Alehouse",
                "place_address": "320 E Dahlia Ave<br />Palmer, AK 99645",
                "icon": "icon-pizza",
                "icon_size": 0.6,
                "text_offset": 2.5,
                "text_size": 14
              },
              "geometry": {
                "coordinates": [
                  -149.110032,
                  61.600009
                ],
                "type": "Point"
              },
              "id": "d4c52e57a7a9cb5aea23dac7afab748c"
            },
            {
              "type": "Feature",
              "properties": {
                "place_name": "Bleeding Heart Brewery",
                "place_address": "562 S Denali St, Palmer,<br />AK 99645",
                "icon": "icon-beer",
                "icon_size": 0.4,
                "text_offset": 2.5,
                "text_size": 14
              },
              "geometry": {
                "coordinates": [
                  -149.109845,
                  61.600922
                ],
                "type": "Point"
              },
              "id": "48cb1b6c06a7d14483e5d77b8aa21a6f"
            },
            {
              "type": "Feature",
              "properties": {
                "place_name": "Matanuska Brewing Company",
                "place_address": "513 S Valley Way, Palmer,<br />AK 99645",
                "icon": "icon-burger",
                "icon_size": 0.6,
                "text_offset": 2.5,
                "text_size": 14
              },
              "geometry": {
                "coordinates": [
                  -149.110488,
                  61.601029
                ],
                "type": "Point"
              },
              "id": "5b89dfbaf709c1002c52b33f"
            },
          ]
        }
      })

      // bus routes - line layer
      // see https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#line
      map.addLayer({
        id: "alopex-icons",
        source: "alopex-locations",
        type: 'symbol',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': ['get', 'icon_size'],
          'text-field': ['get', 'place_name'],
          'text-size': ['get', 'text_size'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': ['get', 'text_offset'],
          'text-justify': 'auto',
        }
      })
    })

    /**
     * Event handler for defining what happens when a user clicks on the map
     * In this example, we are checking if the user has clicked on a bus route
     * If they have, we want to render a popup with the data for the selected
     * bus route
     * Else, do nothing
     */
    map.on("click", (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["alopex-icons"]
      })
      if (features.length > 0) {
        const feature = features[0]
        // create popup node
        const popupNode = document.createElement("div")
        ReactDOM.render(
          <Popup
            placeName={feature?.properties?.place_name}
            placeAddress={feature?.properties?.place_address}
            phone={feature?.properties?.phone}
          />,
          popupNode
        )
        popUpRef.current
          .setLngLat(e.lngLat)
          .setDOMContent(popupNode)
          .addTo(map)
      }
    })

    // cleanup function to remove map on unmount
    return () => map.remove()
  }, [])
  const addAnalyitics = () => {
    // Creates a timeout to call submitForm after one second.
    setTimeout(1000);
    window.dataLayer.push({
     "event" : "netlifyFormSubmission"
   });}

   const ogImage = !!data.contentJson.seoFields.ogImage ? `https://www.alopexid.com${data.contentJson.seoFields.ogImage.replace('/static', '')}"` : null

  return (
    <Layout>
      <SEO title={data.contentJson?.seoFields?.seoTitle} description={data.contentJson?.seoFields?.seoDesc} ogImage={ogImage} />
      <Box backgroundColor={color.alpha}>
        <Container maxWidth={breakpoints.xl} pt={spacing.header} pb={spacing.section} color={colors.brand.white}>
          <H1 variant="pageHeader" color={colors.brand.white} mb={spacing.groups}>Talk to us</H1>
          <form name="Contact" id="contactForm" onSubmit={addAnalyitics} method="POST" data-netlify="true" netlify>
          <Box mb="30px" sx={{ 'input[type="text"], select, textarea': { minHeight: '55px', color: color.alpha } }}>
            <Center>
              <Box as="p" mb={spacing.icon} maxWidth="700px" textAlign={'center'}>We value your time.
Alopex works with partners who are actively seeking superior services. We are not the budget option. Our projects measure in the thousands, not the hundreds. Please confirm that your organization is seeking this level of service.
              </Box>
            </Center>
            <Center>
              <Checkbox isRequired>Our organization is seeking premium services.</Checkbox>
            </Center>
            </Box>
            <SimpleGrid columns={[1, null, 2]} gap="10px" mb="10px" sx={{ 'input[type="text"], select, textarea': { minHeight: '55px', color: color.alpha } }}>
              <FormControl id="firstName" isRequired>
                <Input type="text" name="firstName" placeholder="First Name" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} pattern="\S.*\S" />
              </FormControl>
              <FormControl id="lastName" isRequired>
                <Input type="text" name="lastName" placeholder="Last Name" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} pattern="\S.*\S" />
              </FormControl>
              <FormControl id="email" isRequired>
                <Input name="Email" type="email" placeholder="Email" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="phone" isRequired>
                <Input name="Phone" type="tel" placeholder="Phone" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} pattern="\S.*\S" />
              </FormControl>
            </SimpleGrid>
            <SimpleGrid columns={[1, null, 1]} gap="10px" mb="10px">
              <FormControl id="companyName">
                <Input type="text" name="Company Name" placeholder="Company Name" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
            <Textarea name="Inquiry" display="block" mb="10px" placeholder="Tell us about your goals." borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} pattern="\S.*\S" required />
            </SimpleGrid>
            <SimpleGrid display="none">
              <input type="text" value="" class="utm_source" name="utm_source" />
              <input type="text" value="" class="utm_medium" name="utm_medium" />
              <input type="text" value="" class="utm_term" name="utm_term" />
              <input type="text" value="" class="utm_campaign" name="utm_campaign" />
              <input type="text" value="" class="utm_content" name="utm_content" />
            </SimpleGrid>
            <input type="hidden" name="form-name" value="Contact" />
            <Input
              type="submit"
              mt="20px"
              value="Send"
              _hover={{
                color: color.alpha,
              }}
            />
          </form>
        </Container>
      </Box>
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1897.6142426580634!2d-149.11051348377407!3d61.60065203390524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x56c91defba0e51d3%3A0xdf865e26944de097!2sAlopex%20Interaction%20Design!5e0!3m2!1sen!2sus!4v1614639927343!5m2!1sen!2sus" width="100%" height="450" style={{border:0}} allowFullscreen="" loading="lazy" /> */}
      <Box
        position="relative"
        sx={{
          '@media only screen and (max-width: 640px)': {
            '&::before, &::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: '80%',
              right: 0,
              zIndex: 10,
            },
            '&::before': {
              left: 0,
              right: '80%'
            }
          }
        }}
      >
        <div
          ref={mapContainer}
          style={{width: '100%', height: '50vh'}}
        />
      </Box>
      <Newsletter title="Hear from us" copy={data.contentJson.newsletterCopy} origin="contact" />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query aloContact {
    site {
      siteMetadata {
        title
      }
    }
    contentJson(slug: {eq: "contact"}) {
      title
      newsletterCopy
      seoFields{
        seoTitle
        seoDesc
        ogImage
      }
    }
  }
`

export default ContactPage
